<template>
    <v-container fluid class="ma-4">
        <v-data-table-server
                v-model:items-per-page="itemsPerPage"
                v-model:expanded="expanded"
                :search="search"
                :headers="headers"
                :items-length="totalItems"
                :items="serverItems"
                :loading="loading"
                class="elevation-1 pa-4 results__table"
                show-expand
                item-value="week_started_at"
                @update:itemsPerPage="changeItemsPrePage"
                @update:page="loadItemsPage"
                @update:sortBy="loadSortPage"
                @update:modelValue="loadItems"
        >
            <template v-slot:top>
                <v-row>
                    <v-col>
                        <VueDatePicker
                                v-model="date"
                                model-type="yyyy-MM-dd"
                                :disabled-week-days="[7, 0, 1, 2, 3, 4, 5]"
                                @update:model-value="loadItems"
                                auto-apply
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.week_started_at`]="{ item }">
                {{ moment(item.selectable.week_started_at * 1000).format('DD.MM.YYYY') }} -
                {{ moment(item.selectable.week_ended_at * 1000).format('DD.MM.YYYY') }}
            </template>
            <template v-slot:[`item.ft`]="{ item }">
                {{ item.selectable.fast_team_amount }}/{{ item.selectable.cashback_amount }}
            </template>
            <template v-slot:[`item.activations_count`]="{ item }">
                {{ item.selectable.activations_count }}
            </template>
            <template v-slot:[`item.pro_upgrades_count`]="{ item }">
                {{ item.selectable.pro_upgrades_count }}
            </template>
            <template v-slot:[`item.rewards_total_amount`]="{ item } ">
                {{ item.selectable.rewards_total_amount }} ({{ item.selectable.rewards_to_turnover_ratio }} %)
            </template>
          <template v-slot:[`item.manual_offices_received`]="{ item } ">
            <v-text-field
                v-model="item.selectable.manual_offices_received"
                variant="outlined"
                hide-details
                type="number"
                placeholder="Факт. начисление офисам"
                density="compact"
            ></v-text-field>
            <v-btn
                class="mt-2"
                size="x-small"
                @click="() => onBtnSaveManualOfficesReceived(item.selectable)"
            >
              Сохранить
            </v-btn>
            </template>
          <template v-slot:[`item.data-table-expand`]="{ item, toggleExpand, isExpanded }">
            <td v-if="item.selectable.dayResults" class="text-start">
              <v-icon
                  :style="{'transform: rotate(180deg)': isExpanded(item)}"
                  @click="toggleExpand(item)"
                  :class="{'v-data-table__expand-icon--active' : isExpanded(item)}"
              >mdi-chevron-down</v-icon>
            </td>
          </template>
          <template v-slot:expanded-row="{ item }">
            <tr class="v-data-table__tr v-data-table__tr--inner" v-if="item.selectable.dayResults">
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05); height: 40px"  v-for="headerItem in headers" :key="headerItem.title">{{ headerItem.title }}</td>
            </tr>
            <tr class="v-data-table__tr" v-for="(innerItem, index) in item.selectable.dayResults" :key="index">
<!--              week_started_at-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ moment(innerItem.day_started_at * 1000).format('DD.MM.YYYY') }} -
                {{ moment(innerItem.day_ended_at * 1000).format('DD.MM.YYYY') }}
              </td>
<!--              activations_count-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.activations_count }}
              </td>
<!--             pro_upgrades_count-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.pro_upgrades_count }}
              </td>
<!--              pvs-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.pvs }}
              </td>
<!--              turnover-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.turnover }}
              </td>
<!--              rewards_total_amount-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.rewards_total_amount }} ({{ innerItem.rewards_to_turnover_ratio }} %)
              </td>
<!--              ft-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.fast_team_amount }}/{{ innerItem.cashback_amount }}
              </td>
<!--              points_earned-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.points_earned }}
              </td>
<!--              world_wide_bonus_total_amount-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.world_wide_bonus_total_amount }}
              </td>
<!--              offices_received-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.offices_received }}
              </td>
<!--              company_profit_total_amount-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.company_profit_total_amount }}
              </td>
<!--              trading_cycles_completed-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.trading_cycles_completed }}
              </td>
<!--              fees_total_amount-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.fees_total_amount }}
              </td>
<!--              deposits_on_chain_total_amount-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.deposits_on_chain_total_amount }}
              </td>
<!--              manual_offices_received-->
              <td class="v-data-table__td"  style="background: rgba(0,0,0,.05);height: 40px;">
                {{ innerItem.manual_offices_received }}
              </td>
            </tr>
          </template>
        </v-data-table-server>
    </v-container>
</template>
<script>
import {getWeeksResults, editManualOfficesReceived, getDayResults} from "../plugins/api-service";
  import moment from 'moment';
  import currencyFormatter from "../mixins/currencyFormatter"

  export default {
    name: 'UsersAccounts',
    mixins: [currencyFormatter],
    data: () => ({
      itemsPerPage: 10,
      headers: [
        { title: 'Финансовая неделя', key: 'week_started_at', align: 'start', sortable: false },
        { title: 'Активации', key: 'activations_count', align: 'start', sortable: false },
        { title: 'Перешли на PRO', key: 'pro_upgrades_count', align: 'start', sortable: false },
        { title: 'PV', key: 'pvs', align: 'start'},
        { title: 'Оборот', key: 'turnover', align: 'start'},
        { title: 'Бонусов выплачено', key: 'rewards_total_amount', align: 'start', fixed: true},
        { title: 'FT и Cashback', key: 'ft', align: 'start'},
        { title: 'Points начислено', key: 'points_earned', align: 'start'},
        { title: 'WW выплачено', key: 'world_wide_bonus_total_amount', align: 'start'},
        { title: 'Офисам начислено', key: 'offices_received', align: 'start', sortable: false },
        { title: 'Остаток', key: 'company_profit_total_amount', align: 'start'},
        { title: 'Циклов завершено', key: 'trading_cycles_completed', align: 'start'},
        { title: 'Снято комиссии', key: 'fees_total_amount', align: 'start'},
        { title: 'Депозиты onchain', key: 'deposits_on_chain_total_amount', align: 'start'},
        { title: 'Факт. начисление офисам', key: 'manual_offices_received', align: 'start'},
      ],
      serverItems: [],
      loading: true,
      totalItems: 9999999,
      page: 1,
      search: {
        id: null,
        first_name: '',
        email: ''
      },
      sort: {},
      date: '',
      expanded: [],
      expandedFirstEl: false,
    }),
    mounted() {
      this.loadItems();
    },
    methods: {
      moment,
      async loadItemsPage(page) {
        this.loadItems({
          page,
          order_by: this.sort.order_by,
          order_direction: this.sort.order_direction
        });
      },
      async loadSortPage(params) {
        let requestParams = {};
        if(params && params.length && params[0]['key'] && params[0]['key']) {
          requestParams.order_by = params[0].key;
          requestParams.order_direction = params[0].order;
        }

        this.sort = requestParams;

        this.loadItems(requestParams);
      },
      async loadItems(params) {
        this.loading = true;

        if(this.$route.query.id) {
          this.search.id = this.$route.query.id;
        }

        this.$route.query = {};

        let page = params?.page || 1;
        let itemsPerPage = params?.itemsPerPage || (this.itemsPerPage > 0 ? this.itemsPerPage :  this.totalItems);

        let requestParams = {
          offset: (page - 1) * itemsPerPage,
          limit: page === -1 ? this.totalItems : itemsPerPage
          // order_direction: 'desc'
        };

        if(this.date) {
          requestParams.from_week_started_at = this.date;
        }

        await getWeeksResults(requestParams).then(response=>{
          if(response?.success) {
            this.serverItems = response.result;
            this.loading = false;
            this.fetchDaysResults();
          }
        });
      },
      onBtnSaveManualOfficesReceived(itemSelectable) {
        try {
          editManualOfficesReceived(itemSelectable.week_ended_at, +itemSelectable.manual_offices_received).then(response=>{
            if (!response?.success) {
              alert('Произошла ошибка при сохранении Факт. начисление офисам');
            }
          });
        } catch (err) {
          console.err(err);
        }
      },
      fetchDaysResults() {
        if (!this.serverItems?.length) return;
        this.serverItems.forEach(async (serverItem, index) => {
          const dayResults = await getDayResults(serverItem.week_started_at);
          if (dayResults?.success) {
            serverItem.dayResults = dayResults.result;
            if (!this.expandedFirstEl && index === 0) {
              this.expanded = [serverItem.week_started_at];
              this.expandedFirstEl = true;
            }
          }
        });
      }
    },
  }
</script>
<style lang="scss" scoped>
    .adm-container {
        min-height: calc(100vh - 64px);
    }
    .results__table {
      :deep(.v-data-table-column--fixed) {
        white-space: nowrap;
      }
      :deep(.v-data-table-column--last-fixed) {
        border-right: none;
      }
    }
</style>